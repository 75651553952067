export const getCodeApi = () => {
	let env = process.env.NODE_ENV;
	let appId = localStorage.getItem('appId');
	// let appid = "", 
	let redirect_uri = "";
	console.log('appId', appId, 'env', env);
	switch (env) {
		case 'development':
			// appid = 'wx63c0381ec035dd60';//测试
			redirect_uri = `https://supplier-mp.vchaoxi.com`; //开发
			break;
		case 'production':
			// appid = 'wxe1e490c007d25aad';
			redirect_uri = `https://supplier-mp.youlingui.com`; //生产
			break;
		default:
			// appid = 'wx63c0381ec035dd60';//测试
			redirect_uri = `https://supplier-mp.vchaoxi.com`; //开发
			break;
	}
	
	window.location.href =
	`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
}

export const getUrlKey = (name) => {
	return (
		decodeURIComponent(
			(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
				location.href
			) || [, ""])[1].replace(/\+/g, "%20")
		) || null
	);
}
